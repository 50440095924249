@font-face {
    font-family: 'ArialMT';
    src: url('/assets/fonts/ArialMT/subset-ArialMT.eot');
    src: local('Arial MT'), local('ArialMT'),
        url('/assets/fonts/ArialMT/subset-ArialMT.woff2') format('woff2'),
        url('/assets/fonts/ArialMT/subset-ArialMT.woff') format('woff'),
        url('/assets/fonts/ArialMT/subset-ArialMT.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}


// Common
html, body {
	height: 100%;
}
body{
  font-family: Arial, "ArialMT", sans-serif;
	font-size: 16px;
	line-height: 1.5;
	background-color: #fefefe;
}

.container {
	max-width: 1350px;
	&.medium{
		max-width: 1200px;
	}
}
.multiple-columns {
	&--three {
		column-count: 3;  
	}
}

h1, .h1 {
	font-size: 28px;
}
h2, .h2 {
  font-size: 16px;
}
h4, .h4 {
	font-size: 16px;
	color: #0f568d;
	text-transform: uppercase;
	font-weight: 600;
}



ul, ol {
	margin-bottom: 1.5rem;
	&.no-bullet {
		list-style: none;
	}
}

.relative {
	position: relative;
}
.position-static {
	position: static !important;
}

.social-networks {
	&__caption{
		font-size: 14px;
		color: #737d85;
		margin-bottom: 0;
	}
	a {
		display: inline-block;
		background-position-y: -44px;
		width: 35px;
		height: 35px;
		background: url("/assets/img/sprite.png") no-repeat;
		border-radius: 50%;
		border: 1px solid rgba(197, 197, 197, 0.48);
		transition: .350s;
		&:not(:last-child){
			margin-right: .5rem;
		}
		&:hover {
			opacity: .9;
			// transform: scale(0.95);
			// box-shadow: 1px 0 3px rgba(0,0,0,.5);
		}
		&[href*="facebook.com"] {
			background-position-x: -26px;
		}
		&[href*="instagram.com"] {
			background-position-x: -100px;
		}
	}
	&--footer {
		a {
			background-position-y: -45px;
			position: relative;
			&:before{
				position: absolute;
				content: "";
				background-position-y: -44px;
				background: url("/assets/img/sprite.png") no-repeat;
				width: 34px;
				height: 34px;
				top: -2px;
				left: 0;
				opacity: 0;
				transition: .350s;
			}
			&:hover {
				&:before {
					opacity: 1;
				}
			}
			&[href*="facebook.com"] {
				background-position-x: -173px;
				&:before {
					background-position-x: -26px;
				}
			}
			&[href*="instagram.com"] {
				background-position-x: -212px;
				&:before {
					background-position-x: -101px;
					top: -1px;
				}
			}
		}
	}
}

.register .ajax_form .error {
	color: #ffc107;
}

.hidetext-box {
	overflow: hidden;
	max-height: 88px;
	position: relative;
	transition: all .35s ease-out;
	&:after {
		content: '';
		position: absolute;
		height: 44px;
		width: 100%;
		bottom: 0;
		left: 0;
		background: linear-gradient(to bottom, transparent, #fff);
		transition: all .35s ease-out;
	}
}

.hide-element {
	opacity: 0;
}

// Bootstrap overwrite

// .form-control {
//   border: 1px solid $primary-color-light;
//   border-color: $primary-color-light !important;

//   &:focus, &:hover {
//     box-shadow: 0 0 0 0.2rem transparentize($primary-color-light, 0.8) !important;
//   }
// }

// .success {
//   background-color: #fff;
//   padding: 2rem;
// }

// input, textarea {
//   &::-webkit-input-placeholder {
//     color: $placeholder-c !important;
//   }

//   &::-moz-placeholder {
//     color: $placeholder-c !important;
//   }

//   &:-ms-input-placeholder {
//     color: $placeholder-c !important;
//   }

//   &:-moz-placeholder {
//     color: $placeholder-c !important;
//   }

//   &::-webkit-outer-spin-button, &::-webkit-inner-spin-button {
//     margin: 0;
//     -webkit-appearance: none;
//   }
// }


// section {
//   padding-top: 40px;
//   padding-bottom: 40px;
// }

// a {
//   color: $primary-color;
//   transition: 0.15s ease-in-out;

//   &:hover {
//     color: $primary-color-light;
//   }
// }

// p {
//   color: $secondary-color;
//   font-size: 19px;
// }

// h1, .h1 {
//   font-size: 69px;
// }

// h2, .h2 {
//   margin-bottom: 50px;
//   font-size: 42px;
//   text-transform: uppercase;
//   text-align: center;

//   span {
//     display: block;
//   }
// }

// h3, .h3 {
//   font-size: 35px;
//   text-transform: uppercase;
//   margin-bottom: 40px;
// }

// h4 {
//   font-size: 22px;
// }

// h5, .h5 {
//   font-size: 19px;
//   margin-top: 40px;
//   text-transform: uppercase;
//   color: #5a5a5a;
//   font-weight: 500;

// }

// h6 {
//   font-size: 17px;
//   font-weight: bold;
//   text-transform: uppercase;
// }

// .relative {
//   position: relative;
// }

// .absolute {
//   position: absolute;
// }

// .row.relative {
//   &:before {
//     content: "";
//     position: absolute;
//     top: 90px;
//     height: 2px;
//     width: 100vw;
//     margin-left: -50vw;
//     margin-right: -50vw;
//     left: 50%;
//     right: 50%;
//     background-color: $primary-color;
//   }
// }

// .h100 {
//   height: 100%;
// }



// strong {
//   &.accent {
//     font-weight: bold;
//     color: $primary-color !important;
//   }
// }

// .mfp-close-btn-in .mfp-close {
//   color: #333;
// }

// .mfp-container {
//   form, .popup-no-page {
//     background-color: #fff;
//     padding: 2rem;
//     max-width: 500px;
//     margin-left: auto;
//     margin-right: auto;
//   }

//   textarea {
//     height: 70px;
//     resize: none;
//   }

//   .popup-no-page {
//     max-width: 700px;
//   }
// }
