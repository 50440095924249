.logo {
	a {
		display: inline-block;
	}	
}

.page-title {
	background: url(../img/menu_inner_bg.png) top center repeat-x;
	text-align: center;
	padding: 1.5rem 0;
	margin-bottom: 2rem;
	// border-bottom: 1px solid #83bce8;
	.h1{
		color: #f4b741;
		font-size: 28px;
		display: inline-flex;
		justify-content: center;
		align-items: center;
		margin-bottom: 0;
		&::before{
			content: "";
			display: inline-block;
			width: 56px;
			height: 61px;
			background-image: url("../img/sprite.png");
			background-repeat: no-repeat;
			background-position-y: -36px;
			margin-right: .5rem;
		}
		&.education:before {
			background-position-x: -772px;
		}
		&.countries:before {
			background-position-x: -535px;
		}
		&.participants:before {
			background-position-x: -480px;
		}
		&.business-projects:before {
			background-position-x: -713px;
		}
		&.planned-events:before {
			background-position-x: -595px;
		}
		&.past-events:before {
			background-position-x: -659px;
		}
		&.about:before {
			background-position-x: -292px;
		}
		&.contacts:before {
			background-position-x: -360px;
		}
		&.feedback:before {
			background-position-x: -424px;
		}
	}
}




// ====
header{
	background: url("../img/header_bg.png") top center repeat;
	position:relative;
	z-index: 5;
	// box-shadow: 0px 10px 8px 0px rgba(50, 50, 50, 0.75);
	box-shadow: 0px 6px 6px 0px #0a4069;
	.nav{
		width: 100%;
		width: 100%;
		padding: 0;
		margin: 0;
		list-style: none;

	}
	.border{
		&-bottom{
			border-bottom: 1px solid #bec2c5!important;
			box-shadow: 0 1px #fff;
			padding: 12px 0 9px;
			margin: 0;
		}
	}

	// .top-contact{
	// 	ul{
	// 		padding: 0;
	// 		margin-top:30px;
	// 		li{
	// 			list-style: none;
	// 			display:inline-block;
	// 			a{
	// 				display: block;
	// 				width: 34px;
	// 				height: 35px;
	// 				background: url("../img/sprite.png") no-repeat;
	// 			}
	// 			&.tel{
	// 				a{
	// 					background-position:-135px -1px;
	// 				}
	// 			}
	// 			&.viber{
	// 				a{
	// 					background-position:-171px -1px;
	// 				}
	// 			}
	// 			&.wa{
	// 				a{
	// 					background-position:-205px -1px;
	// 				}
	// 			}
	// 		}
	// 	}
	// 	dl{
	// 		margin: 10px 0 0;
	// 		dt{
	// 			color: #bc861d;
	// 			font-size:18px;
	// 			font-weight: normal;
	// 		}
	// 		a{
	// 			color: #515f6a;
	// 			font-size: 17px;
	// 			text-decoration: none;
	// 		}
	// 	}
	// }
}