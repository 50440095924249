// ==========================================================================
// Index
// ==========================================================================

.main-slider-wrap{
	width: 100%;
	background: url("../img/banner-bg.jpg") top center no-repeat;
	background-size: cover;
	margin-top: -188px;
	height: 728px;
	position: relative;
	.tns-outer {
		height: 100%;
		position: relative;
		&:before, &:after {
			content: "";
			position: absolute;
			left: 0;
			display: block;
			width: 100%;
			height: 1px;
			background: url("../img/border_bg.png") top left repeat-x;
		}
		&:before {
			top: 0;
		}
		&:after {
			bottom: 2%;
		}
	}
	.container{
		padding-top: 200px;
		height: 100%;
		position: relative;
		.boder-decor{
			top: 70%;
			position: absolute;
		}
	}
	.main-slider{
		height: 31%;
		// top: 36%;
		margin-top: 12%;
		text-align: center;
		&__buttons {
			height: 50%;
			top: 40%;
			max-width: 50px;
			width: 100%;
			background-image: url("../img/arrow_prev.png");
			background-position: center;
			background-size: auto;
			background-repeat: no-repeat;
			background-color: transparent;
			border: none;
			cursor: pointer;
			&.next {
				transform: rotate(180deg);
			}
		}
		h5{
			color: #fff;
			font-size: 48px;
			font-weight: 300;
		}
	}
}

.welcom-participants{
	background: url("../img/news-bg.jpg") top center no-repeat;
	background-size: cover;
	padding: 36px 15px 30px;
	.h2{
		color: #f4b741;
		font-size: 28px;
		font-weight: 300;
	}
	&__item{
		color:#fff;
		position: relative;
		padding-top: 112px;
		&::before{
			background: url("../img/sprite.png") no-repeat;
			position: absolute;
			content: "";
			width: 76px;
			left: 50%;
			margin-left: -38px;
			height: 69px;
			top: 10px;
		}
		&:first-child{
			&::before{
				background-position: -1px -45px;
			}
		}
		&:last-child{
			&::before{
				background-position: -80px -45px;
			}
		}

		&-desc {
			position: relative;
			max-width: 430px;
			margin: 0 auto;
			&:before, &:after {
				content: "";
				display: block;
				left: 0;
				background: url("../img/border_decor.png") top center no-repeat;
				height: 3px;
				width: 100%;
			}
			&:before {
				top: 0;
			}
			&:after {
				bottom: 0;
			}
		}
		p {
			padding-top: 1rem;
		}
	}
}


// .s-welcom-participants
.welcom-participants{
	background: url("../img/news-bg.jpg") top center no-repeat;
	background-size: cover;
	padding: 36px 15px 30px;
	.h2{
		color: #f4b741;
		font-size: 28px;
		font-weight: 300;
	}
	&__item{
		color:#fff;
		position: relative;
		padding-top: 112px;
		&::before{
			background: url("../img/sprite.png") no-repeat;
			position: absolute;
			content: "";
			width: 76px;
			left: 50%;
			margin-left: -38px;
			height: 69px;
			top: 10px;
		}
		&:first-child{
			&::before{
				background-position: -1px -45px;
			}
		}
		&:last-child{
			&::before{
				background-position: -80px -45px;
			}
		}

		&-desc {
			position: relative;
			max-width: 430px;
			margin: 0 auto;
			&:before, &:after {
				content: "";
				display: block;
				left: 0;
				background: url("../img/border_decor.png") top center no-repeat;
				height: 3px;
				width: 100%;
			}
			&:before {
				top: 0;
			}
			&:after {
				bottom: 0;
			}
		}
		p {
			padding-top: 1rem;
		}
	}
}


// s-list-pages
.list-pages {
	background-color: #fcfcfc; /* Old browsers */
	background-image: linear-gradient(to bottom, #fcfcfc 0%,#fcfcfc 19%,#c7c7c7 63%,#c7c7c7 100%);
	padding: 30px 0 20px;
	text-align: center;

	.border {
		border: 1px solid rgba(175, 175, 175, 0.7);
		padding-bottom: 15px;
		padding-top: 15px;
		overflow: hidden;

		@for $i from 1 through 6 {
			&:nth-child(#{$i}) {
				background: url("../img/bg-news-0#{$i}.jpg") top center / cover no-repeat;
			}
		}
	} 
	
	&__item {
		background-color: rgba(250,250,250, .7);
		padding: 55px 0;
		margin: 1rem;
		font-size: 27px;
		font-weight: 300;
		text-shadow: 1px 1px 1px #fff;
		color: #000;
		transition: .450s;
		height: 85%;
		&:hover {
			background-color: rgba(0,0,0, .7);
			color: #fff;
			text-decoration: none;
			text-shadow: 1px 1px 1px #000;
			transform: scale(1.2);
			.list-pages__item-desc{ 
				border-top: 1px solid #fff;
			}
		}
		
		&-title{
			display: block;
		}
		&-desc {
			display: block;
			margin: 0 auto;
			text-align: center;
			padding: 0 20px 0;
			width: 65px;
			border-top: 1px solid #716f6e;
		}
	}
}


// ==========================================================================
// Education
// ==========================================================================

.participants-item {
	padding-top: 30px;
	padding-bottom: 30px;
	a {
		font-weight: 400;
		text-decoration: underline;
		color: $link-content-c;
		&:hover{
			text-decoration: none;
		}
	}
}

// ==========================================================================
// Countries
// ==========================================================================

.countries-list{
	// &__item{
	// 	&:nth-child(3n+2) {
	// 		padding-left: 5%;
	// 	}
	// 	&:nth-child(3n+3) {
	// 		padding-left: 10%;
	// 	}
	// }
	a {
		display: inline-flex;
		color:#0e558c;
		align-items: center;
		transition: .100s;
		overflow: hidden;
		&:hover {
			// filter: grayscale(60%);
			.flag-icon-background {
				transform: rotateY(-25deg);
				// transform: rotateX(-360deg);
				// transition: 1s ease;
				box-shadow: 6px 8px 27px -5px rgba(0,0,0,.5);
				// transform: scale(1.1);
			}
		}
	}
	.flag-icon-background {
		margin-right: .5rem;
		transition: .350s;
	}
}

// ==========================================================================
// Past Events
// ==========================================================================
.event-item {
	&__content {
		time {
			color: #708E9B;
			font-weight: normal;
		}
		.h2 {
			color: #aaa;
			font-weight: normal;
			a {
				color: #000;
			}
		}
	}
}