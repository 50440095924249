// TOP NAV
.icon-home{
	width: 10px;
	// height: 19px;
	height: 25px;
	background: url(../img/sprite.png) no-repeat;
	background-position: 0 0;
	padding: 0 .57rem !important;
	// margin-top: 10px;
}

.nav {
	list-style: none;
}

.top-nav{
	min-height: 50px;
	// padding-top: .5rem;
	// padding-bottom: .5rem;
	background-color: #0e558c;
	border-bottom: 1px solid #034476;
	z-index: 6;
	li{
		display: inline-block;
			button[data-toggle]{
				// padding-right: 32px;
				padding-right: 2.5vw;
			}
			.jq-selectbox__dropdown{
				ul{
					li{
						display: block;
					}
				}
			}
		}
}

.nav-info {
	justify-content: space-between;
	align-items: center;
	max-width: 345px;
	&__link {
		display: block;
		color:#fff;
		text-decoration: none;
		font-size: 14px;
		// padding: 9px 20px;
		padding-top: 9px;
		padding-bottom: 9px;
		// padding-left:20px;
		// padding-right:20px;
		transition: .250s ease-in-out;
		&:after {
			content: "";
			display: block;
			width: 0;
			border-bottom: 2px solid #87aac6;
			transform: scale(0);
			transition: all .25s ease;
		}
		&:hover, &.active {
			// opacity: .7;
			text-decoration: none;
			color:#fff;
			&:after {
				width: 100%;
				transform: scale(1);
			}

		}
	}
}

.primary-menu {
	margin: 0;
	list-style: none;
	padding: 0;
	li {
		flex: 1 1 auto;
		position: relative;
		&:not(:first-child) {
			&:before {
				content: "";
				position: absolute;
				background-image: url("/assets/img/vertical-dots.png");
				// background-position: center;
				background-repeat: repeat-y;
				top: 20%;
				left: 0;
				height: 60%;
				width: 1px;
			}
		}
	}
	a{
		display: flex;
		justify-content: center;
		padding-top: 15px;
		padding-bottom: 15px;
		color:#000;
		font-size: 16px;
		font-weight: 600;
		text-shadow: 1px 1px 2px #fff;
		border: 1px solid transparent;
		position: relative;
		transition: .2s ease;
		&:hover,
		&.active{
			color: #fff;
			background-color: #0e558c;
			border-top: 1px solid #0a4470;
			border-left: 1px solid #0a4470;
			border-right: 1px solid #146aac;
			text-decoration: none;
			text-shadow: 1px 1px 2px #000;
			&:after {
				content: "";
				position: absolute;
				left: 0;
				bottom: -15px;
				width: 100%;
				height: 15px;
				background: url("/assets/img/menu_inner_bg.png") top center repeat-x;
				// border-bottom: 10px solid #0e558c;
			}
		}
	}
}

.index .primary-menu a {
	&:hover,
	&.active {
		&:after {
			display: none;
		}
	}
}


// END TOP NAV


//
// Mobile Menu
// ==========================================================================
.menu-btn {
	padding: 5px;
	cursor: pointer;
}
	
.bar1, .bar2, .bar3 {
	width: 35px;
	height: 3px;
	background-color: $primary-color;
	margin: 6px 0;
	transition: 0.4s;
}
	
.mobile-menu {
	visibility: hidden;
	position: fixed;
	top: 0;
	left: 0;
	height: 100vh;
	max-width: 300px;
	width: 100%;
	padding: 10px;
	background-color: $primary-color;
	color: #fff;
	z-index: 999999;
	transition: .250s;
	transform: translateX(-320px);
	overflow: auto;
	padding-bottom: 2rem;
	.nav-info {
		// display: block;
		a {
			// font-size: 16px;
			// font-weight: 600;
			padding-left: 0;
			padding-right: 0;
		}
	}
	li:before {
		display: none;
	}
	a {
		color: #fff;
		padding: 1rem;
		display: block;
		text-shadow: 1px 1px 2px #000;
		transition: .350s;
		border: none;
		&:hover, 
		&.active {
			border-top: none;
			border-right: none;
			border-left: 3px solid #83bce8;
		}
	}
	li:after {
		display: none;
	}
	ul {
		list-style: none;
		padding: 0;
		display: block !important;
	}


.mobile-menu-close {
	height: 1rem;
	position: absolute;
	bottom: 0;
	text-align: center;
	left: 0;
	width: 100%;
	svg {
		height: 2rem;
	}
}
.close-x {
  stroke: #fff;
  fill: transparent;
  stroke-linecap: round;
  stroke-width: 1;
}

}
.overlay {
	position: fixed;
	top: 0;
	left: 0;
	height: 100vh;
	width: 100%;
	background-color: rgba(0,0,0,.6);
	visibility: hidden;
	z-index: 999999;
	&.is-visible {
		visibility: visible;
		.mobile-menu {
			visibility: visible;
			transform: translateX(0);
			box-shadow: 0 5px 15px rgba(0, 0, 0, 5);
		}
	}
}
