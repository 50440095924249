@import "_modules/_accordion";

/* ==========================================================================
	 LK
	 ========================================================================== */
.lk {
	.pagination {
		margin: 0;
	}
	.page-title {
		.bar1, .bar2, .bar3 {
			background-color: #fff !important;
		}
	}
}

.datepicker-here {
	background-image: url(/assets/img/icons/icon-date.png);
	background-repeat: no-repeat;
	padding-right: 1rem;
	background-position: 95% center;
	max-width: 200px;
	height: 30px;
}


.page-title--lk {
	text-align: left;
	padding-top: 2rem;
	padding-bottom: 0rem;
	.h2 {
		font-size: 28px;
		display: inline-block;
		color: #fff;
		margin-bottom: 0;
		max-width: 270px;
		width: 100%;
		position: relative;
		&:after {
			content: "";
			position: absolute;
			top: 0;
			right: 0;
			height: 33px;
			width: 0px;
			border-right: 1px solid #6998bc;
		}
	}
	
}

.lk-nav {
	list-style: none;
	padding: 0;
	text-transform: uppercase;
	max-width: 100%;
	width: 100%;
	margin-bottom: 0;
	li {
		flex: 1 1 auto;
	}
	a {
		position: relative;
		color: #fff;
		display: block;
		font-size: 14px;
		padding-top: .5rem;
		padding-bottom: 1.5rem;
		// border-bottom: 6px solid transparent;
		text-align: center;
		&:after {
			position: absolute;
			bottom: 0;
			left: 0;
			content: "";
			display: block;
			width: 0;
			border-bottom: 6px solid #83bce8;
			transform: scale(0);
			transition: all .25s ease;
		}
		&:hover,
		&.active {
			color: #83bce8;
			text-decoration: none;
			&:after {
				width: 100%;
				transform: scale(1);
			}
			// border-bottom: 6px solid #83bce8;
		}
	}
}



// ==========================================================================
// CONTENT
// ==========================================================================

.headline {
	&.question {
		text-align: left;
		padding-left: 10vw;
		background-image: url(/assets/img/lk/icons/icon-question.png);
		background-position: 5% center;
		background-repeat: no-repeat;
	}
}



// .form-profile
.form-profile {
	.border-top {
		border-color: #a4a9ad;
	}
	textarea {
		resize: none;
		height: 84px;
	}
}

//
// Page Functions
// ==========================================================================
.form-functions {
	.label {
		margin-bottom: 0;
	}
	label {
		font-size: 14px;
	}
	.col-form-label.text-right{
		padding: 0;
	}
	.dates {
		input {
			max-width: 130px;
			width: 100%;
		}
	}
}

.profile-image {
	label {
    position: relative;
    width: 200px;
    cursor: pointer;
    border: 1px solid rgba(219,221,223,.7);
    height: 200px;
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
		img {
			vertical-align: top;
		}
		.edit {
			position: absolute;
			visibility: hidden;
			top: 0;
			left: 0;
			margin-left: 0;
			padding: 15px;
			background-color: #fff;
			background-image: url("/assets/img/icons/icon-edit-active.png");
			background-position: center;
			transition: .2s;
		}
		&:hover {
			.edit {
				visibility: visible;
				width: 18px;
				height: 18px;
				opaciti: 1;
			}
		}
	}
}
.user-info-list {
	list-style: none;
	padding-left: 0;
	margin-left: 0;
	&__item{
		font-size: 16px;
		&--fullname {
			text-transform: uppercase;
			font-weight: bold;
		}
		&--phone, &--email {
			display: flex;
			align-items: center;
			&:before {
				content: "";
				background-image: url(/assets/img/emai-phone.png);
				display: inline-block;
				width: 18px;
				height: 18px;
				margin-right: 1rem;
			}
		}
		&--phone {
			&:before {
				background-position: 0 bottom;
			}
		}
		&--email {
			&:before {
				height: 14px;
			}
		}
	}
}


// Request Status
.request__title {
	margin-bottom: 0;
	font-size: 16px;
	color: #000;
	text-transform: uppercase;
	font-weight: 600;
	border-right: 1px solid #d6d8da;
	padding-top: 0.5rem;
	padding-bottom: .5rem;
	padding-right: 2rem;
}
.request__status {
	display: inline-block;
	padding-top: 32px;
	width: 62px;
	margin-right: 3rem;
	background-repeat: no-repeat;
	background-position: center top;
	font-size: 13px;
	text-align: center;
	// $colors: #acc78f, #f3a2a2, #88a9c2, #b4b4b4;
	// // @each $status, $color in (new, acc78f), (canceled, f3a2a2), (work, 88a9c2), (completed, b4b4b4) {
	// @each $status in new, canceled, work, completed {
	// 	&--#{$status} {
	// 		background-image: url("/assets/img/icons/icon-status-#{$status}.png");
	// 	}
	// }
	&--new {
		background-image: url("/assets/img/icons/icon-status-new.png");
		color: #acc78f;
		&:after {
			content: "новая";
			display: block;
		}
	}
	&--work {
		background-image: url("/assets/img/icons/icon-status-work.png");
		color: #88a9c2;
		&:after {
			content: "в работе";
			display: block;
		}
	}
	&--completed {
		background-image: url("/assets/img/icons/icon-status-completed.png");
		color: #b4b4b4;
		&:after {
			content: "выполнена";
			display: block;
		}
	}
	&--canceled {
		background-image: url("/assets/img/icons/icon-status-canceled.png");
		color: #f3a2a2;
		&:after {
			content: "отменена";
			display: block;
		}
	}
}

.filter {
	&__request-status, &__active-time {
		max-width: 200px;
		border-color: #cfd1d3;
	}
}

.edit, .remove {
	display: inline-block;
	margin-left: 1.5rem;
	padding-left: 1.5rem;
	color: #0f568d;
	text-decoration: underline;
	background-repeat: no-repeat;
	background-position: left center;
	transition: .350s;
	&:hover {
		opacity: .7;
	}
}

.edit {
	background-image: url(/assets/img/icons/icon-edit.png);
}

.form-profile {
	.icon {
		position: absolute;
		top: 10px;
		right: -13px;
		width: 18px;
		height: 18px;
	}
	.form-control:focus + .icon {
		&.edit {
			transition: .250s;
			background-image: url(/assets/img/icons/icon-edit-active.png);
			&:hover {
				opacity: 1;
			}
		}
	}
}

.remove {
	background-image: url(/assets/img/icons/icon-delete.png);
}

@media only screen and (max-width : 768px) {
	.accordion{
		&__item-header{
			padding-left: 0;
			padding-right: 0;
		}
		.edit, .remove {
			margin-left: 1rem;
			text-indent: -9999px;
			white-space: nowrap;   
		}
	}
	.request {
		&__status {
		margin-right: 1rem;
		min-width: 62px;
		}
		&__title {
			font-size: 14px;
			padding-right: .5rem;
		}
	}
}
// @media only screen and (max-width : 576px) {
// }