.calendar, #Calendar .calendar{

	&__week, &__header {
		// display: -ms-grid;
		// display: grid;
		// -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
		// -ms-grid-rows: 100px;
		// grid-template-columns: repeat(7, 1fr);
	}
	
	&__week {
		// grid-auto-rows: 100px;
		text-align: right;
		border-bottom: 1px solid #a4a9ad;
		&:first-child {
			border-top: 1px solid #a4a9ad;
		}
		
	}
	&__header {
		display: flex;
		justify-content: space-around;
		font-size: 14px;
		text-transform: uppercase;
		grid-auto-rows: 50px;
		align-items: center;
		text-align: center;
		font-weight: bold;
		border-bottom: 1px solid #a4a9ad;
		.dow {
			padding-top: .5rem;
			padding-bottom: .5rem;
		}
	}
	&__month {
		padding-top: 1.5rem;
		padding-bottom: 1.5rem;
		background-color: transparentize(#f4ecb6, .5);
		padding-left: 2rem;
		padding-right: 2rem;
		text-transform: uppercase;
		font-size: 14px;
		cursor: pointer;
		.month__name {
			text-decoration: underline;
			color: #999999;
			&.active {
				text-decoration: none;
				font-weight: bold;
				color: #000;
			}
		}
	}
	&__year {
		font-size: 18px;
		font-weight: bold;
		padding-right: 1%;
		border-right: 1px solid #a4a9ad;
	}
	&__day , .emptyday {
		// width: 100px;
		// flex: 1 auto;
		// grid-auto-rows: 100px;
		min-height: 100px;
		background-color: #d6d8da;
		padding: 16px;
		position: relative;
		border-right: 1px solid #a4a9ad;
		cursor: pointer;
		transition: .2s;
		&:first-child {
			border-left: 1px solid #a4a9ad;
		}
		&:hover {
			background-color: #fff;
		}
	}
	.emptyday {
		background-color: #fff;
	// 	border-right: 1px solid #a4a9ad;
	}
	.day__number {
		position: absolute;
		top: .5rem;
		right: .5rem;
		font-weight: bold;
		font-size: 16px;
		color: #8f8f8f;
	}

	.event {
		font-size: 12px;
		line-height: 1.2;
		text-align: left;
		a {
			color: #5e82a7;
		}
	}

}