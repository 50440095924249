// // Large Devices, Wide Screens
// @media only screen and (max-width : 1200px){}
// // Medium Devices, Desktops
// @media only screen and (max-width : 992px) {}
// // ?
// @media only screen and (max-width : 800px) {}
// //Small Devices, Tablets
// @media only screen and (max-width : 768px) {}
// // Extra Small Devices, Phones
// @media only screen and (max-width : 480px) {}
// // Custom, iPhone Retina
// @media only screen and (max-width : 320px) {}

@media only screen and (max-width : 800px) {
	.primary-menu a {
		font-size: 15px;
	}
}

@media only screen and (max-width : 768px) {
	.multiple-columns {
		&--three {
			column-count: 2;  
		}
	}
	.main-slider-wrap {
		margin-top: 0;
		// padding-top: 0;
		height: auto;
		.container {
			padding-top: 0;
		}
		.main-slider {
			h5 {
				font-size: 2rem;
			}
		}
	}
	.tns-ovh {
		overflow: hidden;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
	.headline.question {
		padding-left: 5rem !important;
	}
}


@media only screen and (max-width : 578px) {
	// .countries-list{
	// 	&__item{
	// 		&:nth-child(3n+2) {
	// 			padding-left: 15px;
	// 		}
	// 		&:nth-child(3n+3) {
	// 			padding-left: 15px;
	// 		}
	// 	}
	// }

	.footer {
		padding-bottom: 20px;
		text-align: center !important;
		.social-networks--footer {
			text-align: center !important;
			p {
				margin-bottom: 1rem;
			}
		}
	}
}

@media only screen and (max-width : 480px) {
	.multiple-columns {
		&--three {
			column-count: 1;  
		}
	}
	.top-nav {
		.container {
			padding-left: .5rem;
			padding-right: 0;
		}

		.nav-auth {
			width: 100%;
			justify-content: space-between;
		}
		li button[data-toggle] {
			padding-right: 1vw;
		}
	}
}