.accordion {
	&__item {
		transition: .350s;
		&-header {
			padding: 0.75rem 2rem;
			margin-bottom: 0;
			// background-color: rgba(0, 0, 0, 0.03);
			background-color: #fff;
			border-top: 1px solid rgba(0, 0, 0, 0.125);
			border-bottom: 1px solid rgba(0, 0, 0, 0.125);
			display: flex;
			justify-content: space-between;
			align-items: center;
			cursor: pointer;
			&:after {
				content: "";
				background-image: url(/assets/img/icons/arr-down.png);
				background-size: 100%;
				background-position: center;
				width: 16px;
				height: 11px;
				float: right;
				// margin-left: 5px;
			}
			&.is-open {
				background-color: transparentize(#f4ecb6, .5);
				&:after {
					transform: rotateX(180deg);
				}
			}
		}
		&-body {
			// display: none;
			visibility: hidden;
			padding-left: 7.5vw;
			padding-right: 1rem;
			// padding-top: 1rem;
			// padding-bottom: 1rem;
			background-color: transparentize(#dbdddf, .2);
			max-height: 0;
			overflow: hidden;
			transition: all 0.2s ease-out;
			p:first-child {
				color: #000;
				margin-top: 2rem;
			}
			p:last-child {
				margin-bottom: 2rem;
			}
			&.is-open {
				// display: block;
				visibility: visible;
			}
		}
	}
}



// .accordion {
//     background-color: #eee;
//     color: #444;
//     cursor: pointer;
//     padding: 18px;
//     width: 100%;
//     border: none;
//     text-align: left;
//     outline: none;
//     font-size: 15px;
//     transition: 0.4s;
// }

// .active, .accordion:hover {
//     background-color: #ccc;
// }

// .accordion:after {
//     content: '\002B';
//     color: #777;
//     font-weight: bold;
//     float: right;
//     margin-left: 5px;
// }

// .active:after {
//     content: "\2212";
// }

// .panel {
//     padding: 0 18px;
//     background-color: white;
//     max-height: 0;
//     overflow: hidden;
//     transition: max-height 0.2s ease-out;
// }