// @import "libs/bootstrap.css";
@import "libs/tiny-slider";
@import "libs/nice-select";

@import "_base/_vars";
@import "_base/_base";

@import "_layout/_navigation";
@import "_layout/_header";

@import "_layout/_footer";

@import "_modules/buttons";
@import "_modules/forms";
@import "_modules/calendar";

@import "_pages/pages";

%link-underline {
	&:after {
		position: absolute;
		bottom: 0;
		left: 0;
		content: "";
		display: block;
		width: 0;
		border-bottom: 2px solid #83bce8;
		transform: scale(0);
		transition: all .25s ease;
	}
	&:hover,
	&.active {
		color: #83bce8;
		text-decoration: none;
		&:after {
			width: 100%;
			transform: scale(1);
		}
	}
}

.wrapper {
	overflow: hidden;
	max-width: 100%;
	width: 100%;
	min-height: 100vh;
	display: -ms-flexbox;
	display: flex;
	flex-direction: column;
}

.content {
	background-image: linear-gradient(to bottom, #fff 85%, #e6e6e6 100% );
	padding-bottom: 8rem;
	-ms-flex: 1;
	flex: 1 0 auto;

}

.headline{
	background-color: #f8f8f9;
	padding: 34px 40px;
	margin-bottom: 2rem;
	border-top: 1px solid #d6d8da;
	border-bottom: 1px solid #d6d8da;
	font-weight: bold;
	text-transform: uppercase;
	text-align: center;
}

.border-dots{
	width: 100%;
	height: 1px;
	background: url("../img/border_bg.png") top left repeat-x;
}

.border-decor{
	background: url("../img/border_decor.png") top center no-repeat;
	height: 3px;
	width: 100%;
}

.bg-odd:nth-child(odd) {
	background-color: $odd-item-c;
}

.dropdown{
	display: flex !important;
	align-items: center;
	.label {
		font-size: 14px;
		display: flex;
		align-items: center;
		margin-bottom: 0;
		color: #fff;
	}
	>button{
		position: relative;
		display: inline-flex;
		justify-content: space-between;
		align-items: center;
		// position:relative;
		background-color: transparent;
		border: none;
		color: #fff;
		outline: none;
		font-size: 14px;
		cursor: pointer;
		padding: 0;
		&::before{
			// position: absolute;
			// top: 7px;
			// left: -7px;
			margin-right: 0.5rem;
			content: "";
			width: 20px;
			height: 20px;
			background-image: url("../img/sprite.png");
			background-repeat: no-repeat;
		}
		@extend %link-underline;
		&:after{
			bottom: -.5rem;
		}
		&.registration {
			&::before{
				background-position: -37px -127px;
			}
		}
		&.login{
				&::before{
					background-position: -5px -127px;
				}
		}
	}
	a {
		color: #fff;
		font-size: 14px;
		text-decoration: underline;
	}
}

// FIX ME
// old
.dropdown__menu{
	position: absolute;
	top: 100%;
	left: 0;
	z-index: 1000;
	display: none;
	float: left;
	min-width: 10rem;
	max-width: 294px;
	font-size: 1rem;
	color: #212529;
	list-style: none;
	background-clip: padding-box;
	// -
	background-color: #0e558c;
	padding: 32px 22px;
	border: none;
	border-radius:0;
	box-shadow: 0 3px 5px rgba(0, 0, 0, 0.48);
	.topLoginForm, .topRegisterForm{
		min-width: 250px;
		input{
			background-color: transparent;
			border: 1px solid #5a97c8;
			color: #fff!important;
			border-radius: 0;
			&[type="text"]::-webkit-input-placeholder {
				color: #fff;
			}
			&[type="text"]::-moz-placeholder {
				color: #fff;
			}
			&[type="password"]::-webkit-input-placeholder {
				color: #fff;
			}
			&[type="password"]::-moz-placeholder {
				color: #fff;
			}
		}
		.btn{
			width: 100%;
			background: #0e558c;
			border: 1px solid #5a97c8;
			color: #fff;
			border-radius: 0;
			&:hover {
				background-color: #fff;
				border-color: #0e558c;
				color: #0e558c !important;
			}
		}
	}
}



// FIX ME
// old
.pagination{
	padding: 25px 0;
	ul{
		margin: 0 auto;
		li{
			list-style: none;
			display: inline-block;
			padding: 0 15px;
			a{
				color:#000;
				font-size: 16px;
				text-decoration: none;
				&.active{
					font-weight: bold;
				}
			}
		}
	}
}




// old styles
.styler{
	margin-top:8px;
	min-width: 138px;
	&.jq-selectbox{
		li{
			padding-left: 35px;
			position:relative;
			&::before{
				background: url("../img/sprite.png") no-repeat;
				content: "";
				width: 37px;
				height: 22px;
				position: absolute;
				left: -5px;
				top: -1px;
			}
			&.lang-ru{
				&::before{
					background-position: -245px -5px;
				}
			}
			&.lang-en{
				&::before{
					background-position: -283px -5px;
				}
			}
			&.lang-fr{
				&::before{
					background-position: -323px -5px;
				}
			}
			&.lang-ar{
				&::before{
					background-position: -364px -5px;
				}
			}
			&.lang-es{
				&::before{
					background-position: -399px -5px;
				}
			}
			&.lang-ch{
				&::before{
					background-position: -434px -5px;
				}
			}
		}
		.jq-selectbox__select-text{
			padding-left: 25px;
		}
	}
	.jq-selectbox__select-text{
		&::before{
			background: url("../img/sprite.png") no-repeat;
			content: "";
			width: 37px;
			height: 22px;
			position: absolute;
			left: -5px;
			top: -3px;
		}
	}
	&.lang-ru{
		.jq-selectbox__select-text{
			&::before{
				background-position: -245px -5px;
			}
		}
	}
	&.lang-en{
		.jq-selectbox__select-text{
			&::before{
				background-position: -283px -5px;
			}
		}
	}
	&.lang-fr{
		.jq-selectbox__select-text{
			&::before{
				background-position: -323px -5px;
			}
		}
	}
	&.lang-ar{
		.jq-selectbox__select-text{
			&::before{
				background-position: -364px -5px;
			}
		}
	}
}
// .nav-pills .nav-link.active, .nav-pills .show > .nav-link{
// 	background: none;
// }
// label{
// 	span{
// 		vertical-align: sup;
// 		margin-top: 2px;
// 		display: inline-block;
// 	}
// }



// FIX ME
// .innerPage{
.planned-events, .past-events{
	.planned-list{
		.wrap{
			padding: 35px 50px 75px 50px;
			h2{
				font-size: 16px;
				font-weight: 600;
				text-transform: uppercase;
			}
			.text-center{
				margin: 35px 0 25px;
			}
			.social-link{
				display: inline-block;
				padding: 10px 85px;
				border: 1px solid #0d548b;
				color: #0d548b;
				text-decoration: none;
			}
		}
		.img-wrap{
			width: 100%;
			img{
				width: 100%;
				height: auto;
			}
			a{
				display: inline-block;
				width: 100%;
				text-align: center;
				position: relative;
				span{
					display: inline-block;
					height: 100%;
					position: relative;
					background-size: cover;
					background-repeat: no-repeat;
					top:10px;
					left:10px;
					img{
						position: relative;
						top:-10px;
						left:-10px;
						z-index: 5;
						border: 2px solid #fff;
					}
				}
			}
		}
		h2{
			font-size: 16px;
			font-weight: 600;
			text-transform: uppercase;
		}
		.content-wrap{
				height: 120px;
				overflow: hidden;
		}
		.row{
			padding-top: 20px;
			padding-bottom: 20px;
			margin-bottom: 20px;
		}
		.more{
			margin-top: 20px;
			background: none;
			color:#10578e;
			border: none;
			cursor: pointer;
		}
	}
}

@import "_base/_media";

@import "admin";
