.footer {
	background-color: #3d4a54;
	padding: 20px 0 115px;
	font-size: 14px;
	color: #9ca0a3;

	&__nav {
		a{
			color:#e6e6e6;
			font-size:16px;
			&:after {
				border-color: #fff;
			}
			&.icon-home {
				display: none;
			}
			&:nth-child(2) {
				padding-left: 0;
			}
		}
	}
}


//====
// .social-links--bottom{
// 	dl{
// 		margin-right: 60px;
// 		dt{
// 			font-size: 14px;
// 			color:#9ca0a3;
// 			font-weight: 300;
// 		}
// 		dd{
// 			margin-top:5px;
// 			ul{
// 				li{
// 					list-style: none;
// 					display: inline-block;
// 					a{
// 						display: inline-block;
// 						width: 35px;
// 						height: 35px;
// 						background: url("../img/sprite.png") no-repeat;
// 						&.fb{
// 							background-position: -173px -44px;
// 							&:hover{
// 								background-position: -25px -1px;
// 							}
// 						}
// 						&.vk{
// 							background-position: -249px -44px;
// 						}
// 						&.inst{
// 							background-position: -211px -44px;
// 							&:hover{
// 								background-position: -100px 0;
// 							}
// 						}
// 					}
// 				}
// 			}
// 		}
// 	}
// }

// footer {
//   background-color: $primary-color;
//   color: #fff;
//   padding-top: 40px;
//   padding-bottom: 15px;
//   font-weight: 300;
// }

// .footer__copyright {
//   margin-top: 40px;
//   font-size: 14px;
// }

// #scrollUp {
//   display: none;
//   position: fixed;
//   bottom: 30px;
//   right: 30px;
//   opacity: 0.8;
//   cursor: pointer;
//   border-radius: 50%;
//   background-color: $primary-color;
//   box-shadow: 0 5px 15px rgba(0, 0, 0, 0.48);
//   color: #ffffff !important;
//   transition: all 0.25s ease-in-out;
//   line-height: 0;
//   padding: 15px;

//   &:hover {
//     opacity: 1;
//     box-shadow: 0 2px 8px rgba(0, 0, 0, 0.48);
//   }
// }
