// form-feedback
.form-feedback{
	margin-top:35px;
	// margin-bottom: 265px;
	width: 100%;
	input, select, textarea{
		border: 1px solid #d0d1d3;
		padding-left: 10px;
		padding-right: 10px;
		color:#000;
		font-size: 14px;
		width: 100%;
		&::-webkit-input-placeholder {
			color: #000!important;
		}
		&::-moz-placeholder {
			color: #000!important;
		}
	}
	textarea{
		resize: none;
	}
	select{
		font-weight: 600;
	}
	button{
		border: 1px solid #0d548b;
		background: none;
		color: #0d548b;
		font-size: 14px;
		font-weight: bold;
		display: inline-block;
		padding: 12px 95px;
	}
		
	.fileinput-button {
		input{
			cursor: pointer;
			direction: ltr;
			font-size: 14px;
			margin: 0;
			opacity: 0;
			position: absolute;
			right: 0;
			top: 0;
		}
		.fileinput-button-label {
			text-decoration: underline;
			position: relative;
			&:after{
				content: "";
				background: url("../img/sprite.png") no-repeat;
				background-position: -68px -131px;
				width: 19px;
				height: 20px;
				position: absolute;
				right:-27px;
				top: -1px;
			}
		}
	}
	.assessment{
		text-align:right;
		padding:0;
		display: inline-block;
		li{
			list-style: none;
			display: inline-block;
			a{
				display: inline-block;
				height: 20px;
				width: 17px;
				background: url(../img/sprite.png) no-repeat;
				background-position: -98px -127px;
				&.star-gray{
					background-position: -123px -127px;
				}
			}
		}
	}
}


//
// Helpers
// ==========================================================================


.form-control {
	&, &-sm {
		border-radius: 0;
	}
	&:invalid {
		border: 1px solid rgba(255, 0, 42, 0.7);
		&:focus {
			box-shadow: 0 0 0 0.2rem rgba(255, 0, 42, 0.25);
		}
	}
}

.custom-checkbox {
	position: relative;
	&__label {
		position: relative;
		display: flex;
		align-items: center;
		line-height: 1rem;
		min-width: 16px;
		min-height: 16px;
		padding-left: 32px;
		padding-top: 4px;
		&:before {
			position: absolute;
			content: "";
			background: transparent;
			background-size: 100%;
			width: 16px;
			height: 16px;
			left: 0;
			top: 1px;
			box-shadow: inset 0 0 1px 1px rgba(0, 0, 0, 0.3), 1px 1px 3px 0px rgba(255, 255, 255, 0.8);
		}
	}
	&__input {
		position: absolute;
		opacity: 0;
	}
	
}
.custom-checkbox__input:checked + label {
	background: url("/assets/img/checked.png") no-repeat;
}

// .form-functions {

// }