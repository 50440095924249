@charset "UTF-8";
.tns-outer {
  padding: 0 !important;
}

.tns-outer [hidden] {
  display: none !important;
}

.tns-outer [aria-controls], .tns-outer [data-action] {
  cursor: pointer;
}

.tns-slider {
  -webkit-transition: all 0s;
  -moz-transition: all 0s;
  transition: all 0s;
}

.tns-slider > .tns-item {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.tns-horizontal.tns-subpixel {
  white-space: nowrap;
}

.tns-horizontal.tns-subpixel > .tns-item {
  display: inline-block;
  vertical-align: top;
  white-space: normal;
}

.tns-horizontal.tns-no-subpixel:after {
  content: '';
  display: table;
  clear: both;
}

.tns-horizontal.tns-no-subpixel > .tns-item {
  float: left;
  margin-right: -100%;
}

.tns-no-calc {
  position: relative;
  left: 0;
}

.tns-gallery {
  position: relative;
  left: 0;
  min-height: 1px;
}

.tns-gallery > .tns-item {
  position: absolute;
  left: -100%;
  -webkit-transition: transform 0s, opacity 0s;
  -moz-transition: transform 0s, opacity 0s;
  transition: transform 0s, opacity 0s;
}

.tns-gallery > .tns-slide-active {
  position: relative;
  left: unset !important;
}

.tns-gallery > .tns-moving {
  -webkit-transition: all 0.25s;
  -moz-transition: all 0.25s;
  transition: all 0.25s;
}

.tns-lazy-img {
  -webkit-transition: opacity 0.6s;
  -moz-transition: opacity 0.6s;
  transition: opacity 0.6s;
  opacity: 0.6;
}

.tns-lazy-img.loaded {
  opacity: 1;
}

.tns-ah {
  -webkit-transition: height 0s;
  -moz-transition: height 0s;
  transition: height 0s;
}

.tns-ovh {
  overflow: hidden;
}

.tns-visually-hidden {
  position: absolute;
  left: -10000em;
}

.tns-transparent {
  opacity: 0;
  visibility: hidden;
}

.tns-fadeIn {
  opacity: 1;
  filter: alpha(opacity=100);
  z-index: 0;
}

.tns-normal, .tns-fadeOut {
  opacity: 0;
  filter: alpha(opacity=0);
  z-index: -1;
}

.tns-t-subp2 {
  margin: 0 auto;
  width: 310px;
  position: relative;
  height: 10px;
  overflow: hidden;
}

.tns-t-ct {
  width: 2333.33333%;
  width: -webkit-calc(100% * 70 / 3);
  width: -moz-calc(100% * 70 / 3);
  width: calc(100% * 70 / 3);
  position: absolute;
  right: 0;
}

.tns-t-ct:after {
  content: '';
  display: table;
  clear: both;
}

.tns-t-ct > div {
  width: 1.42857%;
  width: -webkit-calc(100% / 70);
  width: -moz-calc(100% / 70);
  width: calc(100% / 70);
  height: 10px;
  float: left;
}

.nice-select {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  background-color: #fff;
  border-radius: 5px;
  border: solid 1px #e8e8e8;
  box-sizing: border-box;
  clear: both;
  cursor: pointer;
  display: block;
  float: left;
  font-family: inherit;
  font-size: 14px;
  font-weight: normal;
  height: 42px;
  line-height: 40px;
  outline: none;
  padding-left: 18px;
  padding-right: 30px;
  position: relative;
  text-align: left !important;
  transition: all 0.2s ease-in-out;
  user-select: none;
  white-space: nowrap;
  width: auto;
}

.nice-select:hover {
  border-color: #dbdbdb;
}

.nice-select:active, .nice-select.open, .nice-select:focus {
  border-color: #999;
}

.nice-select:after {
  border-bottom: 2px solid #999;
  border-right: 2px solid #999;
  content: '';
  display: block;
  height: 5px;
  margin-top: -4px;
  pointer-events: none;
  position: absolute;
  right: 12px;
  top: 50%;
  transform-origin: 66% 66%;
  transform: rotate(45deg);
  transition: all 0.15s ease-in-out;
  width: 5px;
}

.nice-select.open:after {
  transform: rotate(-135deg);
}

.nice-select.open .list {
  opacity: 1;
  pointer-events: auto;
  transform: scale(1) translateY(0);
}

.nice-select.disabled {
  border-color: #ededed;
  color: #999;
  pointer-events: none;
}

.nice-select.disabled:after {
  border-color: #cccccc;
}

.nice-select.wide {
  width: 100%;
}

.nice-select.wide .list {
  left: 0 !important;
  right: 0 !important;
}

.nice-select.right {
  float: right;
}

.nice-select.right .list {
  left: auto;
  right: 0;
}

.nice-select.small {
  font-size: 12px;
  height: 36px;
  line-height: 34px;
}

.nice-select.small:after {
  height: 4px;
  width: 4px;
}

.nice-select.small .option {
  line-height: 34px;
  min-height: 34px;
}

.nice-select .list {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 0 1px rgba(68, 68, 68, 0.11);
  box-sizing: border-box;
  margin-top: 4px;
  opacity: 0;
  overflow: hidden;
  padding: 0;
  pointer-events: none;
  position: absolute;
  top: 100%;
  left: 0;
  transform-origin: 50% 0;
  transform: scale(0.75) translateY(-21px);
  transition: all 0.2s cubic-bezier(0.5, 0, 0, 1.25), opacity 0.15s ease-out;
  z-index: 9;
}

.nice-select .list:hover .option:not(:hover) {
  background-color: transparent !important;
}

.nice-select .option {
  cursor: pointer;
  font-weight: 400;
  line-height: 40px;
  list-style: none;
  min-height: 40px;
  outline: none;
  padding-left: 18px;
  padding-right: 29px;
  text-align: left;
  transition: all 0.2s;
}

.nice-select .option:hover, .nice-select .option.focus, .nice-select .option.selected.focus {
  background-color: #f6f6f6;
}

.nice-select .option.selected {
  font-weight: bold;
}

.nice-select .option.disabled {
  background-color: transparent;
  color: #999;
  cursor: default;
}

.no-csspointerevents .nice-select .list {
  display: none;
}

.no-csspointerevents .nice-select.open .list {
  display: block;
}

@font-face {
  font-family: 'ArialMT';
  src: url("/assets/fonts/ArialMT/subset-ArialMT.eot");
  src: local("Arial MT"), local("ArialMT"), url("/assets/fonts/ArialMT/subset-ArialMT.woff2") format("woff2"), url("/assets/fonts/ArialMT/subset-ArialMT.woff") format("woff"), url("/assets/fonts/ArialMT/subset-ArialMT.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

html, body {
  height: 100%;
}

body {
  font-family: Arial, "ArialMT", sans-serif;
  font-size: 16px;
  line-height: 1.5;
  background-color: #fefefe;
}

.container {
  max-width: 1350px;
}

.container.medium {
  max-width: 1200px;
}

.multiple-columns--three {
  column-count: 3;
}

h1, .h1 {
  font-size: 28px;
}

h2, .h2 {
  font-size: 16px;
}

h4, .h4 {
  font-size: 16px;
  color: #0f568d;
  text-transform: uppercase;
  font-weight: 600;
}

ul, ol {
  margin-bottom: 1.5rem;
}

ul.no-bullet, ol.no-bullet {
  list-style: none;
}

.relative {
  position: relative;
}

.position-static {
  position: static !important;
}

.social-networks__caption {
  font-size: 14px;
  color: #737d85;
  margin-bottom: 0;
}

.social-networks a {
  display: inline-block;
  background-position-y: -44px;
  width: 35px;
  height: 35px;
  background: url("/assets/img/sprite.png") no-repeat;
  border-radius: 50%;
  border: 1px solid rgba(197, 197, 197, 0.48);
  transition: .350s;
}

.social-networks a:not(:last-child) {
  margin-right: .5rem;
}

.social-networks a:hover {
  opacity: .9;
}

.social-networks a[href*="facebook.com"] {
  background-position-x: -26px;
}

.social-networks a[href*="instagram.com"] {
  background-position-x: -100px;
}

.social-networks--footer a {
  background-position-y: -45px;
  position: relative;
}

.social-networks--footer a:before {
  position: absolute;
  content: "";
  background-position-y: -44px;
  background: url("/assets/img/sprite.png") no-repeat;
  width: 34px;
  height: 34px;
  top: -2px;
  left: 0;
  opacity: 0;
  transition: .350s;
}

.social-networks--footer a:hover:before {
  opacity: 1;
}

.social-networks--footer a[href*="facebook.com"] {
  background-position-x: -173px;
}

.social-networks--footer a[href*="facebook.com"]:before {
  background-position-x: -26px;
}

.social-networks--footer a[href*="instagram.com"] {
  background-position-x: -212px;
}

.social-networks--footer a[href*="instagram.com"]:before {
  background-position-x: -101px;
  top: -1px;
}

.register .ajax_form .error {
  color: #ffc107;
}

.hidetext-box {
  overflow: hidden;
  max-height: 88px;
  position: relative;
  transition: all .35s ease-out;
}

.hidetext-box:after {
  content: '';
  position: absolute;
  height: 44px;
  width: 100%;
  bottom: 0;
  left: 0;
  background: linear-gradient(to bottom, transparent, #fff);
  transition: all .35s ease-out;
}

.hide-element {
  opacity: 0;
}

.icon-home {
  width: 10px;
  height: 25px;
  background: url(../img/sprite.png) no-repeat;
  background-position: 0 0;
  padding: 0 .57rem !important;
}

.nav {
  list-style: none;
}

.top-nav {
  min-height: 50px;
  background-color: #0e558c;
  border-bottom: 1px solid #034476;
  z-index: 6;
}

.top-nav li {
  display: inline-block;
}

.top-nav li button[data-toggle] {
  padding-right: 2.5vw;
}

.top-nav li .jq-selectbox__dropdown ul li {
  display: block;
}

.nav-info {
  justify-content: space-between;
  align-items: center;
  max-width: 345px;
}

.nav-info__link {
  display: block;
  color: #fff;
  text-decoration: none;
  font-size: 14px;
  padding-top: 9px;
  padding-bottom: 9px;
  transition: .250s ease-in-out;
}

.nav-info__link:after {
  content: "";
  display: block;
  width: 0;
  border-bottom: 2px solid #87aac6;
  transform: scale(0);
  transition: all .25s ease;
}

.nav-info__link:hover, .nav-info__link.active {
  text-decoration: none;
  color: #fff;
}

.nav-info__link:hover:after, .nav-info__link.active:after {
  width: 100%;
  transform: scale(1);
}

.primary-menu {
  margin: 0;
  list-style: none;
  padding: 0;
}

.primary-menu li {
  flex: 1 1 auto;
  position: relative;
}

.primary-menu li:not(:first-child):before {
  content: "";
  position: absolute;
  background-image: url("/assets/img/vertical-dots.png");
  background-repeat: repeat-y;
  top: 20%;
  left: 0;
  height: 60%;
  width: 1px;
}

.primary-menu a {
  display: flex;
  justify-content: center;
  padding-top: 15px;
  padding-bottom: 15px;
  color: #000;
  font-size: 16px;
  font-weight: 600;
  text-shadow: 1px 1px 2px #fff;
  border: 1px solid transparent;
  position: relative;
  transition: .2s ease;
}

.primary-menu a:hover, .primary-menu a.active {
  color: #fff;
  background-color: #0e558c;
  border-top: 1px solid #0a4470;
  border-left: 1px solid #0a4470;
  border-right: 1px solid #146aac;
  text-decoration: none;
  text-shadow: 1px 1px 2px #000;
}

.primary-menu a:hover:after, .primary-menu a.active:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -15px;
  width: 100%;
  height: 15px;
  background: url("/assets/img/menu_inner_bg.png") top center repeat-x;
}

.index .primary-menu a:hover:after, .index .primary-menu a.active:after {
  display: none;
}

.menu-btn {
  padding: 5px;
  cursor: pointer;
}

.bar1, .bar2, .bar3 {
  width: 35px;
  height: 3px;
  background-color: #0e558c;
  margin: 6px 0;
  transition: 0.4s;
}

.mobile-menu {
  visibility: hidden;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  max-width: 300px;
  width: 100%;
  padding: 10px;
  background-color: #0e558c;
  color: #fff;
  z-index: 999999;
  transition: .250s;
  transform: translateX(-320px);
  overflow: auto;
  padding-bottom: 2rem;
}

.mobile-menu .nav-info a {
  padding-left: 0;
  padding-right: 0;
}

.mobile-menu li:before {
  display: none;
}

.mobile-menu a {
  color: #fff;
  padding: 1rem;
  display: block;
  text-shadow: 1px 1px 2px #000;
  transition: .350s;
  border: none;
}

.mobile-menu a:hover, .mobile-menu a.active {
  border-top: none;
  border-right: none;
  border-left: 3px solid #83bce8;
}

.mobile-menu li:after {
  display: none;
}

.mobile-menu ul {
  list-style: none;
  padding: 0;
  display: block !important;
}

.mobile-menu .mobile-menu-close {
  height: 1rem;
  position: absolute;
  bottom: 0;
  text-align: center;
  left: 0;
  width: 100%;
}

.mobile-menu .mobile-menu-close svg {
  height: 2rem;
}

.mobile-menu .close-x {
  stroke: #fff;
  fill: transparent;
  stroke-linecap: round;
  stroke-width: 1;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  visibility: hidden;
  z-index: 999999;
}

.overlay.is-visible {
  visibility: visible;
}

.overlay.is-visible .mobile-menu {
  visibility: visible;
  transform: translateX(0);
  box-shadow: 0 5px 15px black;
}

.logo a {
  display: inline-block;
}

.page-title {
  background: url(../img/menu_inner_bg.png) top center repeat-x;
  text-align: center;
  padding: 1.5rem 0;
  margin-bottom: 2rem;
}

.page-title .h1 {
  color: #f4b741;
  font-size: 28px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0;
}

.page-title .h1::before {
  content: "";
  display: inline-block;
  width: 56px;
  height: 61px;
  background-image: url("../img/sprite.png");
  background-repeat: no-repeat;
  background-position-y: -36px;
  margin-right: .5rem;
}

.page-title .h1.education:before {
  background-position-x: -772px;
}

.page-title .h1.countries:before {
  background-position-x: -535px;
}

.page-title .h1.participants:before {
  background-position-x: -480px;
}

.page-title .h1.business-projects:before {
  background-position-x: -713px;
}

.page-title .h1.planned-events:before {
  background-position-x: -595px;
}

.page-title .h1.past-events:before {
  background-position-x: -659px;
}

.page-title .h1.about:before {
  background-position-x: -292px;
}

.page-title .h1.contacts:before {
  background-position-x: -360px;
}

.page-title .h1.feedback:before {
  background-position-x: -424px;
}

header {
  background: url("../img/header_bg.png") top center repeat;
  position: relative;
  z-index: 5;
  box-shadow: 0px 6px 6px 0px #0a4069;
}

header .nav {
  width: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
}

header .border-bottom {
  border-bottom: 1px solid #bec2c5 !important;
  box-shadow: 0 1px #fff;
  padding: 12px 0 9px;
  margin: 0;
}

.footer {
  background-color: #3d4a54;
  padding: 20px 0 115px;
  font-size: 14px;
  color: #9ca0a3;
}

.footer__nav a {
  color: #e6e6e6;
  font-size: 16px;
}

.footer__nav a:after {
  border-color: #fff;
}

.footer__nav a.icon-home {
  display: none;
}

.footer__nav a:nth-child(2) {
  padding-left: 0;
}

.btn {
  border-radius: 0;
}

.btn-outline-primary {
  color: #0d548b;
  border-color: #5a97c8 !important;
  border-radius: 0;
}

.btn-outline-primary:hover {
  background-color: #0d548b;
}

.form-feedback {
  margin-top: 35px;
  width: 100%;
}

.form-feedback input, .form-feedback select, .form-feedback textarea {
  border: 1px solid #d0d1d3;
  padding-left: 10px;
  padding-right: 10px;
  color: #000;
  font-size: 14px;
  width: 100%;
}

.form-feedback input::-webkit-input-placeholder, .form-feedback select::-webkit-input-placeholder, .form-feedback textarea::-webkit-input-placeholder {
  color: #000 !important;
}

.form-feedback input::-moz-placeholder, .form-feedback select::-moz-placeholder, .form-feedback textarea::-moz-placeholder {
  color: #000 !important;
}

.form-feedback textarea {
  resize: none;
}

.form-feedback select {
  font-weight: 600;
}

.form-feedback button {
  border: 1px solid #0d548b;
  background: none;
  color: #0d548b;
  font-size: 14px;
  font-weight: bold;
  display: inline-block;
  padding: 12px 95px;
}

.form-feedback .fileinput-button input {
  cursor: pointer;
  direction: ltr;
  font-size: 14px;
  margin: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.form-feedback .fileinput-button .fileinput-button-label {
  text-decoration: underline;
  position: relative;
}

.form-feedback .fileinput-button .fileinput-button-label:after {
  content: "";
  background: url("../img/sprite.png") no-repeat;
  background-position: -68px -131px;
  width: 19px;
  height: 20px;
  position: absolute;
  right: -27px;
  top: -1px;
}

.form-feedback .assessment {
  text-align: right;
  padding: 0;
  display: inline-block;
}

.form-feedback .assessment li {
  list-style: none;
  display: inline-block;
}

.form-feedback .assessment li a {
  display: inline-block;
  height: 20px;
  width: 17px;
  background: url(../img/sprite.png) no-repeat;
  background-position: -98px -127px;
}

.form-feedback .assessment li a.star-gray {
  background-position: -123px -127px;
}

.form-control, .form-control-sm {
  border-radius: 0;
}

.form-control:invalid {
  border: 1px solid rgba(255, 0, 42, 0.7);
}

.form-control:invalid:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 0, 42, 0.25);
}

.custom-checkbox {
  position: relative;
}

.custom-checkbox__label {
  position: relative;
  display: flex;
  align-items: center;
  line-height: 1rem;
  min-width: 16px;
  min-height: 16px;
  padding-left: 32px;
  padding-top: 4px;
}

.custom-checkbox__label:before {
  position: absolute;
  content: "";
  background: transparent;
  background-size: 100%;
  width: 16px;
  height: 16px;
  left: 0;
  top: 1px;
  box-shadow: inset 0 0 1px 1px rgba(0, 0, 0, 0.3), 1px 1px 3px 0px rgba(255, 255, 255, 0.8);
}

.custom-checkbox__input {
  position: absolute;
  opacity: 0;
}

.custom-checkbox__input:checked + label {
  background: url("/assets/img/checked.png") no-repeat;
}

.calendar__week, #Calendar .calendar__week {
  text-align: right;
  border-bottom: 1px solid #a4a9ad;
}

.calendar__week:first-child, #Calendar .calendar__week:first-child {
  border-top: 1px solid #a4a9ad;
}

.calendar__header, #Calendar .calendar__header {
  display: flex;
  justify-content: space-around;
  font-size: 14px;
  text-transform: uppercase;
  grid-auto-rows: 50px;
  align-items: center;
  text-align: center;
  font-weight: bold;
  border-bottom: 1px solid #a4a9ad;
}

.calendar__header .dow, #Calendar .calendar__header .dow {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.calendar__month, #Calendar .calendar__month {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  background-color: rgba(244, 236, 182, 0.5);
  padding-left: 2rem;
  padding-right: 2rem;
  text-transform: uppercase;
  font-size: 14px;
  cursor: pointer;
}

.calendar__month .month__name, #Calendar .calendar__month .month__name {
  text-decoration: underline;
  color: #999999;
}

.calendar__month .month__name.active, #Calendar .calendar__month .month__name.active {
  text-decoration: none;
  font-weight: bold;
  color: #000;
}

.calendar__year, #Calendar .calendar__year {
  font-size: 18px;
  font-weight: bold;
  padding-right: 1%;
  border-right: 1px solid #a4a9ad;
}

.calendar__day, .calendar .emptyday, #Calendar .calendar__day, #Calendar .calendar .emptyday {
  min-height: 100px;
  background-color: #d6d8da;
  padding: 16px;
  position: relative;
  border-right: 1px solid #a4a9ad;
  cursor: pointer;
  transition: .2s;
}

.calendar__day:first-child, .calendar .emptyday:first-child, #Calendar .calendar__day:first-child, #Calendar .calendar .emptyday:first-child {
  border-left: 1px solid #a4a9ad;
}

.calendar__day:hover, .calendar .emptyday:hover, #Calendar .calendar__day:hover, #Calendar .calendar .emptyday:hover {
  background-color: #fff;
}

.calendar .emptyday, #Calendar .calendar .emptyday {
  background-color: #fff;
}

.calendar .day__number, #Calendar .calendar .day__number {
  position: absolute;
  top: .5rem;
  right: .5rem;
  font-weight: bold;
  font-size: 16px;
  color: #8f8f8f;
}

.calendar .event, #Calendar .calendar .event {
  font-size: 12px;
  line-height: 1.2;
  text-align: left;
}

.calendar .event a, #Calendar .calendar .event a {
  color: #5e82a7;
}

.main-slider-wrap {
  width: 100%;
  background: url("../img/banner-bg.jpg") top center no-repeat;
  background-size: cover;
  margin-top: -188px;
  height: 728px;
  position: relative;
}

.main-slider-wrap .tns-outer {
  height: 100%;
  position: relative;
}

.main-slider-wrap .tns-outer:before, .main-slider-wrap .tns-outer:after {
  content: "";
  position: absolute;
  left: 0;
  display: block;
  width: 100%;
  height: 1px;
  background: url("../img/border_bg.png") top left repeat-x;
}

.main-slider-wrap .tns-outer:before {
  top: 0;
}

.main-slider-wrap .tns-outer:after {
  bottom: 2%;
}

.main-slider-wrap .container {
  padding-top: 200px;
  height: 100%;
  position: relative;
}

.main-slider-wrap .container .boder-decor {
  top: 70%;
  position: absolute;
}

.main-slider-wrap .main-slider {
  height: 31%;
  margin-top: 12%;
  text-align: center;
}

.main-slider-wrap .main-slider__buttons {
  height: 50%;
  top: 40%;
  max-width: 50px;
  width: 100%;
  background-image: url("../img/arrow_prev.png");
  background-position: center;
  background-size: auto;
  background-repeat: no-repeat;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.main-slider-wrap .main-slider__buttons.next {
  transform: rotate(180deg);
}

.main-slider-wrap .main-slider h5 {
  color: #fff;
  font-size: 48px;
  font-weight: 300;
}

.welcom-participants {
  background: url("../img/news-bg.jpg") top center no-repeat;
  background-size: cover;
  padding: 36px 15px 30px;
}

.welcom-participants .h2 {
  color: #f4b741;
  font-size: 28px;
  font-weight: 300;
}

.welcom-participants__item {
  color: #fff;
  position: relative;
  padding-top: 112px;
}

.welcom-participants__item::before {
  background: url("../img/sprite.png") no-repeat;
  position: absolute;
  content: "";
  width: 76px;
  left: 50%;
  margin-left: -38px;
  height: 69px;
  top: 10px;
}

.welcom-participants__item:first-child::before {
  background-position: -1px -45px;
}

.welcom-participants__item:last-child::before {
  background-position: -80px -45px;
}

.welcom-participants__item-desc {
  position: relative;
  max-width: 430px;
  margin: 0 auto;
}

.welcom-participants__item-desc:before, .welcom-participants__item-desc:after {
  content: "";
  display: block;
  left: 0;
  background: url("../img/border_decor.png") top center no-repeat;
  height: 3px;
  width: 100%;
}

.welcom-participants__item-desc:before {
  top: 0;
}

.welcom-participants__item-desc:after {
  bottom: 0;
}

.welcom-participants__item p {
  padding-top: 1rem;
}

.welcom-participants {
  background: url("../img/news-bg.jpg") top center no-repeat;
  background-size: cover;
  padding: 36px 15px 30px;
}

.welcom-participants .h2 {
  color: #f4b741;
  font-size: 28px;
  font-weight: 300;
}

.welcom-participants__item {
  color: #fff;
  position: relative;
  padding-top: 112px;
}

.welcom-participants__item::before {
  background: url("../img/sprite.png") no-repeat;
  position: absolute;
  content: "";
  width: 76px;
  left: 50%;
  margin-left: -38px;
  height: 69px;
  top: 10px;
}

.welcom-participants__item:first-child::before {
  background-position: -1px -45px;
}

.welcom-participants__item:last-child::before {
  background-position: -80px -45px;
}

.welcom-participants__item-desc {
  position: relative;
  max-width: 430px;
  margin: 0 auto;
}

.welcom-participants__item-desc:before, .welcom-participants__item-desc:after {
  content: "";
  display: block;
  left: 0;
  background: url("../img/border_decor.png") top center no-repeat;
  height: 3px;
  width: 100%;
}

.welcom-participants__item-desc:before {
  top: 0;
}

.welcom-participants__item-desc:after {
  bottom: 0;
}

.welcom-participants__item p {
  padding-top: 1rem;
}

.list-pages {
  background-color: #fcfcfc;
  /* Old browsers */
  background-image: linear-gradient(to bottom, #fcfcfc 0%, #fcfcfc 19%, #c7c7c7 63%, #c7c7c7 100%);
  padding: 30px 0 20px;
  text-align: center;
}

.list-pages .border {
  border: 1px solid rgba(175, 175, 175, 0.7);
  padding-bottom: 15px;
  padding-top: 15px;
  overflow: hidden;
}

.list-pages .border:nth-child(1) {
  background: url("../img/bg-news-01.jpg") top center/cover no-repeat;
}

.list-pages .border:nth-child(2) {
  background: url("../img/bg-news-02.jpg") top center/cover no-repeat;
}

.list-pages .border:nth-child(3) {
  background: url("../img/bg-news-03.jpg") top center/cover no-repeat;
}

.list-pages .border:nth-child(4) {
  background: url("../img/bg-news-04.jpg") top center/cover no-repeat;
}

.list-pages .border:nth-child(5) {
  background: url("../img/bg-news-05.jpg") top center/cover no-repeat;
}

.list-pages .border:nth-child(6) {
  background: url("../img/bg-news-06.jpg") top center/cover no-repeat;
}

.list-pages__item {
  background-color: rgba(250, 250, 250, 0.7);
  padding: 55px 0;
  margin: 1rem;
  font-size: 27px;
  font-weight: 300;
  text-shadow: 1px 1px 1px #fff;
  color: #000;
  transition: .450s;
  height: 85%;
}

.list-pages__item:hover {
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
  text-decoration: none;
  text-shadow: 1px 1px 1px #000;
  transform: scale(1.2);
}

.list-pages__item:hover .list-pages__item-desc {
  border-top: 1px solid #fff;
}

.list-pages__item-title {
  display: block;
}

.list-pages__item-desc {
  display: block;
  margin: 0 auto;
  text-align: center;
  padding: 0 20px 0;
  width: 65px;
  border-top: 1px solid #716f6e;
}

.participants-item {
  padding-top: 30px;
  padding-bottom: 30px;
}

.participants-item a {
  font-weight: 400;
  text-decoration: underline;
  color: #0d548b;
}

.participants-item a:hover {
  text-decoration: none;
}

.countries-list a {
  display: inline-flex;
  color: #0e558c;
  align-items: center;
  transition: .100s;
  overflow: hidden;
}

.countries-list a:hover .flag-icon-background {
  transform: rotateY(-25deg);
  box-shadow: 6px 8px 27px -5px rgba(0, 0, 0, 0.5);
}

.countries-list .flag-icon-background {
  margin-right: .5rem;
  transition: .350s;
}

.event-item__content time {
  color: #708E9B;
  font-weight: normal;
}

.event-item__content .h2 {
  color: #aaa;
  font-weight: normal;
}

.event-item__content .h2 a {
  color: #000;
}

.dropdown > button:after {
  position: absolute;
  bottom: 0;
  left: 0;
  content: "";
  display: block;
  width: 0;
  border-bottom: 2px solid #83bce8;
  transform: scale(0);
  transition: all .25s ease;
}

.dropdown > button:hover, .dropdown > button.active {
  color: #83bce8;
  text-decoration: none;
}

.dropdown > button:hover:after, .dropdown > button.active:after {
  width: 100%;
  transform: scale(1);
}

.wrapper {
  overflow: hidden;
  max-width: 100%;
  width: 100%;
  min-height: 100vh;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
}

.content {
  background-image: linear-gradient(to bottom, #fff 85%, #e6e6e6 100%);
  padding-bottom: 8rem;
  -ms-flex: 1;
  flex: 1 0 auto;
}

.headline {
  background-color: #f8f8f9;
  padding: 34px 40px;
  margin-bottom: 2rem;
  border-top: 1px solid #d6d8da;
  border-bottom: 1px solid #d6d8da;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
}

.border-dots {
  width: 100%;
  height: 1px;
  background: url("../img/border_bg.png") top left repeat-x;
}

.border-decor {
  background: url("../img/border_decor.png") top center no-repeat;
  height: 3px;
  width: 100%;
}

.bg-odd:nth-child(odd) {
  background-color: #f8f8f9;
}

.dropdown {
  display: flex !important;
  align-items: center;
}

.dropdown .label {
  font-size: 14px;
  display: flex;
  align-items: center;
  margin-bottom: 0;
  color: #fff;
}

.dropdown > button {
  position: relative;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  border: none;
  color: #fff;
  outline: none;
  font-size: 14px;
  cursor: pointer;
  padding: 0;
}

.dropdown > button::before {
  margin-right: 0.5rem;
  content: "";
  width: 20px;
  height: 20px;
  background-image: url("../img/sprite.png");
  background-repeat: no-repeat;
}

.dropdown > button:after {
  bottom: -.5rem;
}

.dropdown > button.registration::before {
  background-position: -37px -127px;
}

.dropdown > button.login::before {
  background-position: -5px -127px;
}

.dropdown a {
  color: #fff;
  font-size: 14px;
  text-decoration: underline;
}

.dropdown__menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  max-width: 294px;
  font-size: 1rem;
  color: #212529;
  list-style: none;
  background-clip: padding-box;
  background-color: #0e558c;
  padding: 32px 22px;
  border: none;
  border-radius: 0;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.48);
}

.dropdown__menu .topLoginForm, .dropdown__menu .topRegisterForm {
  min-width: 250px;
}

.dropdown__menu .topLoginForm input, .dropdown__menu .topRegisterForm input {
  background-color: transparent;
  border: 1px solid #5a97c8;
  color: #fff !important;
  border-radius: 0;
}

.dropdown__menu .topLoginForm input[type="text"]::-webkit-input-placeholder, .dropdown__menu .topRegisterForm input[type="text"]::-webkit-input-placeholder {
  color: #fff;
}

.dropdown__menu .topLoginForm input[type="text"]::-moz-placeholder, .dropdown__menu .topRegisterForm input[type="text"]::-moz-placeholder {
  color: #fff;
}

.dropdown__menu .topLoginForm input[type="password"]::-webkit-input-placeholder, .dropdown__menu .topRegisterForm input[type="password"]::-webkit-input-placeholder {
  color: #fff;
}

.dropdown__menu .topLoginForm input[type="password"]::-moz-placeholder, .dropdown__menu .topRegisterForm input[type="password"]::-moz-placeholder {
  color: #fff;
}

.dropdown__menu .topLoginForm .btn, .dropdown__menu .topRegisterForm .btn {
  width: 100%;
  background: #0e558c;
  border: 1px solid #5a97c8;
  color: #fff;
  border-radius: 0;
}

.dropdown__menu .topLoginForm .btn:hover, .dropdown__menu .topRegisterForm .btn:hover {
  background-color: #fff;
  border-color: #0e558c;
  color: #0e558c !important;
}

.pagination {
  padding: 25px 0;
}

.pagination ul {
  margin: 0 auto;
}

.pagination ul li {
  list-style: none;
  display: inline-block;
  padding: 0 15px;
}

.pagination ul li a {
  color: #000;
  font-size: 16px;
  text-decoration: none;
}

.pagination ul li a.active {
  font-weight: bold;
}

.styler {
  margin-top: 8px;
  min-width: 138px;
}

.styler.jq-selectbox li {
  padding-left: 35px;
  position: relative;
}

.styler.jq-selectbox li::before {
  background: url("../img/sprite.png") no-repeat;
  content: "";
  width: 37px;
  height: 22px;
  position: absolute;
  left: -5px;
  top: -1px;
}

.styler.jq-selectbox li.lang-ru::before {
  background-position: -245px -5px;
}

.styler.jq-selectbox li.lang-en::before {
  background-position: -283px -5px;
}

.styler.jq-selectbox li.lang-fr::before {
  background-position: -323px -5px;
}

.styler.jq-selectbox li.lang-ar::before {
  background-position: -364px -5px;
}

.styler.jq-selectbox li.lang-es::before {
  background-position: -399px -5px;
}

.styler.jq-selectbox li.lang-ch::before {
  background-position: -434px -5px;
}

.styler.jq-selectbox .jq-selectbox__select-text {
  padding-left: 25px;
}

.styler .jq-selectbox__select-text::before {
  background: url("../img/sprite.png") no-repeat;
  content: "";
  width: 37px;
  height: 22px;
  position: absolute;
  left: -5px;
  top: -3px;
}

.styler.lang-ru .jq-selectbox__select-text::before {
  background-position: -245px -5px;
}

.styler.lang-en .jq-selectbox__select-text::before {
  background-position: -283px -5px;
}

.styler.lang-fr .jq-selectbox__select-text::before {
  background-position: -323px -5px;
}

.styler.lang-ar .jq-selectbox__select-text::before {
  background-position: -364px -5px;
}

.planned-events .planned-list .wrap, .past-events .planned-list .wrap {
  padding: 35px 50px 75px 50px;
}

.planned-events .planned-list .wrap h2, .past-events .planned-list .wrap h2 {
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
}

.planned-events .planned-list .wrap .text-center, .past-events .planned-list .wrap .text-center {
  margin: 35px 0 25px;
}

.planned-events .planned-list .wrap .social-link, .past-events .planned-list .wrap .social-link {
  display: inline-block;
  padding: 10px 85px;
  border: 1px solid #0d548b;
  color: #0d548b;
  text-decoration: none;
}

.planned-events .planned-list .img-wrap, .past-events .planned-list .img-wrap {
  width: 100%;
}

.planned-events .planned-list .img-wrap img, .past-events .planned-list .img-wrap img {
  width: 100%;
  height: auto;
}

.planned-events .planned-list .img-wrap a, .past-events .planned-list .img-wrap a {
  display: inline-block;
  width: 100%;
  text-align: center;
  position: relative;
}

.planned-events .planned-list .img-wrap a span, .past-events .planned-list .img-wrap a span {
  display: inline-block;
  height: 100%;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  top: 10px;
  left: 10px;
}

.planned-events .planned-list .img-wrap a span img, .past-events .planned-list .img-wrap a span img {
  position: relative;
  top: -10px;
  left: -10px;
  z-index: 5;
  border: 2px solid #fff;
}

.planned-events .planned-list h2, .past-events .planned-list h2 {
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
}

.planned-events .planned-list .content-wrap, .past-events .planned-list .content-wrap {
  height: 120px;
  overflow: hidden;
}

.planned-events .planned-list .row, .past-events .planned-list .row {
  padding-top: 20px;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.planned-events .planned-list .more, .past-events .planned-list .more {
  margin-top: 20px;
  background: none;
  color: #10578e;
  border: none;
  cursor: pointer;
}

@media only screen and (max-width: 800px) {
  .primary-menu a {
    font-size: 15px;
  }
}

@media only screen and (max-width: 768px) {
  .multiple-columns--three {
    column-count: 2;
  }
  .main-slider-wrap {
    margin-top: 0;
    height: auto;
  }
  .main-slider-wrap .container {
    padding-top: 0;
  }
  .main-slider-wrap .main-slider h5 {
    font-size: 2rem;
  }
  .tns-ovh {
    overflow: hidden;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .headline.question {
    padding-left: 5rem !important;
  }
}

@media only screen and (max-width: 578px) {
  .footer {
    padding-bottom: 20px;
    text-align: center !important;
  }
  .footer .social-networks--footer {
    text-align: center !important;
  }
  .footer .social-networks--footer p {
    margin-bottom: 1rem;
  }
}

@media only screen and (max-width: 480px) {
  .multiple-columns--three {
    column-count: 1;
  }
  .top-nav .container {
    padding-left: .5rem;
    padding-right: 0;
  }
  .top-nav .nav-auth {
    width: 100%;
    justify-content: space-between;
  }
  .top-nav li button[data-toggle] {
    padding-right: 1vw;
  }
}

.accordion__item {
  transition: .350s;
}

.accordion__item-header {
  padding: 0.75rem 2rem;
  margin-bottom: 0;
  background-color: #fff;
  border-top: 1px solid rgba(0, 0, 0, 0.125);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.accordion__item-header:after {
  content: "";
  background-image: url(/assets/img/icons/arr-down.png);
  background-size: 100%;
  background-position: center;
  width: 16px;
  height: 11px;
  float: right;
}

.accordion__item-header.is-open {
  background-color: rgba(244, 236, 182, 0.5);
}

.accordion__item-header.is-open:after {
  transform: rotateX(180deg);
}

.accordion__item-body {
  visibility: hidden;
  padding-left: 7.5vw;
  padding-right: 1rem;
  background-color: rgba(219, 221, 223, 0.8);
  max-height: 0;
  overflow: hidden;
  transition: all 0.2s ease-out;
}

.accordion__item-body p:first-child {
  color: #000;
  margin-top: 2rem;
}

.accordion__item-body p:last-child {
  margin-bottom: 2rem;
}

.accordion__item-body.is-open {
  visibility: visible;
}

/* ==========================================================================
	 LK
	 ========================================================================== */
.lk .pagination {
  margin: 0;
}

.lk .page-title .bar1, .lk .page-title .bar2, .lk .page-title .bar3 {
  background-color: #fff !important;
}

.datepicker-here {
  background-image: url(/assets/img/icons/icon-date.png);
  background-repeat: no-repeat;
  padding-right: 1rem;
  background-position: 95% center;
  max-width: 200px;
  height: 30px;
}

.page-title--lk {
  text-align: left;
  padding-top: 2rem;
  padding-bottom: 0rem;
}

.page-title--lk .h2 {
  font-size: 28px;
  display: inline-block;
  color: #fff;
  margin-bottom: 0;
  max-width: 270px;
  width: 100%;
  position: relative;
}

.page-title--lk .h2:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  height: 33px;
  width: 0px;
  border-right: 1px solid #6998bc;
}

.lk-nav {
  list-style: none;
  padding: 0;
  text-transform: uppercase;
  max-width: 100%;
  width: 100%;
  margin-bottom: 0;
}

.lk-nav li {
  flex: 1 1 auto;
}

.lk-nav a {
  position: relative;
  color: #fff;
  display: block;
  font-size: 14px;
  padding-top: .5rem;
  padding-bottom: 1.5rem;
  text-align: center;
}

.lk-nav a:after {
  position: absolute;
  bottom: 0;
  left: 0;
  content: "";
  display: block;
  width: 0;
  border-bottom: 6px solid #83bce8;
  transform: scale(0);
  transition: all .25s ease;
}

.lk-nav a:hover, .lk-nav a.active {
  color: #83bce8;
  text-decoration: none;
}

.lk-nav a:hover:after, .lk-nav a.active:after {
  width: 100%;
  transform: scale(1);
}

.headline.question {
  text-align: left;
  padding-left: 10vw;
  background-image: url(/assets/img/lk/icons/icon-question.png);
  background-position: 5% center;
  background-repeat: no-repeat;
}

.form-profile .border-top {
  border-color: #a4a9ad;
}

.form-profile textarea {
  resize: none;
  height: 84px;
}

.form-functions .label {
  margin-bottom: 0;
}

.form-functions label {
  font-size: 14px;
}

.form-functions .col-form-label.text-right {
  padding: 0;
}

.form-functions .dates input {
  max-width: 130px;
  width: 100%;
}

.profile-image label {
  position: relative;
  width: 200px;
  cursor: pointer;
  border: 1px solid rgba(219, 221, 223, 0.7);
  height: 200px;
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
}

.profile-image label img {
  vertical-align: top;
}

.profile-image label .edit {
  position: absolute;
  visibility: hidden;
  top: 0;
  left: 0;
  margin-left: 0;
  padding: 15px;
  background-color: #fff;
  background-image: url("/assets/img/icons/icon-edit-active.png");
  background-position: center;
  transition: .2s;
}

.profile-image label:hover .edit {
  visibility: visible;
  width: 18px;
  height: 18px;
  opaciti: 1;
}

.user-info-list {
  list-style: none;
  padding-left: 0;
  margin-left: 0;
}

.user-info-list__item {
  font-size: 16px;
}

.user-info-list__item--fullname {
  text-transform: uppercase;
  font-weight: bold;
}

.user-info-list__item--phone, .user-info-list__item--email {
  display: flex;
  align-items: center;
}

.user-info-list__item--phone:before, .user-info-list__item--email:before {
  content: "";
  background-image: url(/assets/img/emai-phone.png);
  display: inline-block;
  width: 18px;
  height: 18px;
  margin-right: 1rem;
}

.user-info-list__item--phone:before {
  background-position: 0 bottom;
}

.user-info-list__item--email:before {
  height: 14px;
}

.request__title {
  margin-bottom: 0;
  font-size: 16px;
  color: #000;
  text-transform: uppercase;
  font-weight: 600;
  border-right: 1px solid #d6d8da;
  padding-top: 0.5rem;
  padding-bottom: .5rem;
  padding-right: 2rem;
}

.request__status {
  display: inline-block;
  padding-top: 32px;
  width: 62px;
  margin-right: 3rem;
  background-repeat: no-repeat;
  background-position: center top;
  font-size: 13px;
  text-align: center;
}

.request__status--new {
  background-image: url("/assets/img/icons/icon-status-new.png");
  color: #acc78f;
}

.request__status--new:after {
  content: "новая";
  display: block;
}

.request__status--work {
  background-image: url("/assets/img/icons/icon-status-work.png");
  color: #88a9c2;
}

.request__status--work:after {
  content: "в работе";
  display: block;
}

.request__status--completed {
  background-image: url("/assets/img/icons/icon-status-completed.png");
  color: #b4b4b4;
}

.request__status--completed:after {
  content: "выполнена";
  display: block;
}

.request__status--canceled {
  background-image: url("/assets/img/icons/icon-status-canceled.png");
  color: #f3a2a2;
}

.request__status--canceled:after {
  content: "отменена";
  display: block;
}

.filter__request-status, .filter__active-time {
  max-width: 200px;
  border-color: #cfd1d3;
}

.edit, .remove {
  display: inline-block;
  margin-left: 1.5rem;
  padding-left: 1.5rem;
  color: #0f568d;
  text-decoration: underline;
  background-repeat: no-repeat;
  background-position: left center;
  transition: .350s;
}

.edit:hover, .remove:hover {
  opacity: .7;
}

.edit {
  background-image: url(/assets/img/icons/icon-edit.png);
}

.form-profile .icon {
  position: absolute;
  top: 10px;
  right: -13px;
  width: 18px;
  height: 18px;
}

.form-profile .form-control:focus + .icon.edit {
  transition: .250s;
  background-image: url(/assets/img/icons/icon-edit-active.png);
}

.form-profile .form-control:focus + .icon.edit:hover {
  opacity: 1;
}

.remove {
  background-image: url(/assets/img/icons/icon-delete.png);
}

@media only screen and (max-width: 768px) {
  .accordion__item-header {
    padding-left: 0;
    padding-right: 0;
  }
  .accordion .edit, .accordion .remove {
    margin-left: 1rem;
    text-indent: -9999px;
    white-space: nowrap;
  }
  .request__status {
    margin-right: 1rem;
    min-width: 62px;
  }
  .request__title {
    font-size: 14px;
    padding-right: .5rem;
  }
}
