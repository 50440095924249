$primary-color: #0e558c;
$odd-item-c: #f8f8f9;
// #dbdddf;
$link-content-c: #0d548b;

// h4 color
// color: #0f568d

// .footer {
//   background-color: #3d4a54;

// .social-networks {
// 	&__caption{
// 	color: #737d85;