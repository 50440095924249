// // Replace botstrap styles
.btn {
  border-radius: 0;
}
.btn{
	&-outline-primary {
		color: $link-content-c;
		border-color: #5a97c8 !important;
		border-radius: 0;
		&:hover {
			background-color: $link-content-c;
		}
	}
}

// .btn {
//   &-outline-success {
//     color: $primary-color-light;
//     border: 2px solid $primary-color-light;
//     text-transform: uppercase;
//     font-weight: bold;

//     &:hover, &:active, &:focus {
//       background-color: $primary-color-light !important;
//       color: #fff;
//       border-color: $primary-color-light !important;
//       box-shadow: none !important;
//     }
//   }

//   &-outline-light {}

//   &-primary {
//     background-color: $primary-color;
//     border-color: $primary-color !important;
//     color: #fff;

//     &:hover, &:active, &:focus {
//       background-color: #fff;
//       border-color: $primary-color !important;
//       box-shadow: none !important;
//       color: $primary-color !important;
//     }
//   }

//   &-secondary {
//     background-color: #fff;
//     color: $primary-color;
//     text-decoration: none !important;

//     &:hover, &:active, &:focus {
//       background-color: darken(#fff, 10%);
//       box-shadow: none !important;
//       color: $primary-color;
//     }
//   }
// }


